.mm1{
    max-width: 600px;
}

/*** Service ***/
.service-item {
    position: relative;
    margin: 65px 0 25px 0;
    box-shadow: 0 0 45px rgba(0, 0, 0, .07);
}

.service-item .service-img {
    position: absolute;
    padding: 12px;
    width: 130px;
    height: 130px;
    top: -65px;
    left: 50%;
    transform: translateX(-50%);
    background: #FFFFFF;
    box-shadow: 0 0 45px rgba(0, 0, 0, .09);
    z-index: 2;
}

.service-item .service-detail {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    z-index: 1;
}

.service-item .service-title {
    position: absolute;
    padding: 65px 30px 25px 30px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #dfdfdf;
    transition: .5s;
}

.service-item:hover .service-title {
    top: -100%;
}

.service-item .service-text {
    position: absolute;
    overflow: hidden;
    padding: 65px 30px 25px 30px;
    width: 100%;
    height: 100%;
    top: 100%;
    left: 0;
    display: flex;
    align-items: center;
    text-align: center;
    background: rgba(13, 110, 253, .7);
    transition: .5s;
}

.service-item:hover .service-text {
    top: 0;
}

.service-item .service-text::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100px;
    top: -100%;
    left: 0;
    transform: skewY(-12deg);
    background: #FFFFFF;
    transition: .5s;
}

.service-item:hover .service-text::before {
    top: -55px;
}

.service-item .btn {
    position: absolute;
    width: 130px;
    height: 50px;
    left: 50%;
    bottom: -25px;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary);
    background: #cbc7c7;
    border: none;
    box-shadow: 0 0 45px rgba(0, 0, 0, .09);
    z-index: 2;
}

.service-item .btn:hover {
    color: #ffffff;
    background: #0d6efd;
}