.p11{
    text-align: justify;
}
.col {
    position: relative;
    overflow: hidden;
  }

  .card {
    position: relative;
    overflow: hidden;
    border: 1px solid #ddd; /* Add a border for a cleaner look */
    transition: transform 0.3s; /* Add a smooth transition effect on transform */
  }

  .card:hover {
    transform: scale(1.05); /* Scale up the card on hover */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow on hover */
  }

  .card-img-top {
    width: 100%;
    height: auto;
  }

  .card-body {
    padding: 15px;
  }

  .conbtt1 {
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
    background-color: #a3a3a3;
    color: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s; /* Add a smooth transition effect on background color */
  }

  .conbtt1:hover {
    background-color: rgba(13, 110, 253, 1); /* Change the background color on hover */
  }

  .card-text {
    margin-top: 15px;
  }